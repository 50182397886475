<template>
  <div>
    <!-- 面包屑 -->
    <h1 style="margin-bottom:10px;">{{ routeTitle }}</h1>
    <Breadcrumb :routes="$store.state.app.breadcrumbRoutes">
      <template #itemRender="{ route, routes, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.meta.title }}
        </span>
        <router-link v-else :to="'/' + paths.join('/')">
          {{ route.meta.title }}
        </router-link>
      </template>
    </Breadcrumb>
  </div>
</template>
<script>
import { defineComponent, computed, watch } from "vue";
import { Breadcrumb } from "ant-design-vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "bread-crumb",
  components: {
    Breadcrumb
  },
  setup() {
    const store = useStore();
    const openKeys = computed(() => store.state.app.openKeys);
    const routeTitle =
      store.state.app.breadcrumbRoutes[
        store.state.app.breadcrumbRoutes.length - 1
      ].meta.title;

    watch(
      () => openKeys.value,
      (val, oldVal) => {
        store.commit("app/UPDATE_PRE_OPEN_KEYS", oldVal);
      },
      {
        deep: true
      }
    );

    return {
      openKeys,
      routeTitle
    };
  }
});
</script>
