//table列配置
export const getColumns = () => {
  //  开票审核
  const columnsList = [
    { type: "checkbox", hidden: true },
    {
      width: 100,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "账号",
      dataIndex: "account_id",
      minWidth: 120
    },
    {
      title: "账户类型",
      dataIndex: "account_type_desc",
      minWidth: 120
    },
    {
      title: "开票金额(元)",
      field: "money",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "账单时间",
      field: "update_time",
      // minWidth: 120,
      cellRender: "p-date"
    }
  ];
  // 开票申请详情
  const columnsDetail = [
    { type: "checkbox", hidden: true },
    {
      width: 100,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "账号",
      dataIndex: "account_id",
      minWidth: 120
    },
    {
      title: "账户类型",
      dataIndex: "account_type_desc",
      minWidth: 120
    },
    {
      title: "开票金额(元)",
      field: "money",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "账单时间",
      field: "create_time",
      minWidth: 120,
      cellRender: "p-date"
    }
  ];
  // 开票记录
  const columnsRecords = [
    { type: "checkbox", hidden: true },
    {
      width: 100,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "申请ID",
      dataIndex: "id",
      align: "left",
      minWidth: 120
    },
    {
      title: "开票金额(元)",
      field: "amount",
      minWidth: 120,
      align: "left",
      cellRender: "p-money"
    },
    {
      title: "申请人",
      dataIndex: "apply_user",
      align: "left",
      minWidth: 120
    },
    {
      title: "申请时间",
      field: "apply_time",
      minWidth: 120,
      align: "left",
      cellRender: "p-date"
    },
    {
      title: "状态",
      dataIndex: "status_desc",
      align: "left",
      minWidth: 120
    },
    {
      title: "快递信息",
      dataIndex: "express_info",
      align: "left",
      minWidth: 120
    }
  ];
  // 开票信息
  const columnsInfo = [
    { type: "checkbox", hidden: true },
    {
      width: 100,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "发票抬头",
      dataIndex: "invoice_title",
      minWidth: 120
    },
    {
      title: "纳税人识别号",
      dataIndex: "tax_number",
      minWidth: 120
    },
    {
      title: "操作",
      dataIndex: "action",
      // minWidth: 140,
      align: "left",
      fixed: "right"
    }
  ];

  const columnsAddress = [
    { type: "checkbox", hidden: true },
    {
      width: 80,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "所在地区",
      dataIndex: "province",
      minWidth: 120
    },
    {
      title: "详细地址",
      dataIndex: "details",
      minWidth: 120
    },
    {
      title: "收件人",
      dataIndex: "contact",
      minWidth: 100
    },
    {
      title: "联系方式",
      dataIndex: "contact_phone",
      minWidth: 100
    },
    {
      title: "企业名称",
      dataIndex: "company_name",
      minWidth: 120
    },
    {
      title: "操作",
      dataIndex: "action",
      minWidth: 140,
      align: "left",
      fixed: "right"
    }
  ];

  return {
    columnsList,
    columnsDetail,
    columnsRecords,
    columnsInfo,
    columnsAddress
  };
};
