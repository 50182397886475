<template>
  <page-layout class="ma-24">
    <!-- 表头面包屑 -->
    <div class="d-flex justify-space-between">
      <Breadcrumbs />
      <a-button @click="handleBack">返回</a-button>
    </div>
    <div class="bg-white px-24 py-16 my-24">
      <ul class="d-flex fz-14">
        <li class="mr-32">
          <span style="color:#808AA4" class="mr-8">申请ID:</span>
          <span style="color:#3C4E64">{{ routeQuery.id }}</span>
        </li>
        <li class="mr-32">
          <span style="color:#808AA4" class="mr-8">申请人:</span>
          <span style="color:#3C4E64">{{ routeQuery.name }}</span>
        </li>
        <li class="mr-32">
          <span style="color:#808AA4" class="mr-8">申请时间:</span>
          <span style="color:#3C4E64">{{
            formatToDateTime(+routeQuery.time, "yyyy.MM.dd")
          }}</span>
        </li>
        <li>
          <span style="color:#808AA4" class="mr-8">开票金额:</span>
          <span style="color:#3C4E64">{{ routeQuery.total / 100 }} 元</span>
        </li>
      </ul>
    </div>
    <p-table
      class="bg-white"
      rowKey="id"
      :dataSource="dataSource"
      :columns="tableColumns.columnsDetail"
    ></p-table>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { getColumns } from "../constants/table-config";
import { Table } from "@wlhy-web-components/common";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { useInvoiceRecordDetailApi } from "@/apis/service";
import Breadcrumbs from "@/components/Breadcrumb/Main.vue";

export default defineComponent({
  components: {
    Breadcrumbs
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const routeQuery = ref(route.query);
    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());
    const invoiceRecordDetail = useInvoiceRecordDetailApi();

    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          invoice_id: route.query.id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await invoiceRecordDetail(data);
        return { records: res.list, total: res.total };
      }
    });

    const handleBack = () => {
      // router.push({
      //   path: "/invoice/service-invoice",
      //   query: { state: 1 }
      // });
      router.go(-1);
    };

    return {
      routeQuery,
      tableColumns,
      tableDataSource,
      handleBack,
      formatToDateTime,
      getConsultingList,
      dataSource: tableDataSource
    };
  }
});
</script>
